import React from "react";
import '../Home/Home.css'
import Footer from "../../component/Footer";

const Home = () => {

    return(
        <React.Fragment>
            <header className="header-home">
                <h3>WELCOME TO EXPLORE SULSEL</h3>
            </header>

                <div class="top-banner">
                    <div class="row">
                        <div class="col banner1">
                        </div>
                        <div class="col banner2">
                        </div>
                        <div class="col banner3">  
                        </div>
                    </div>
                </div>
            
        <nav class="navbar nav-home navbar-expand-lg navbar-light " style={{backgroundColor: "#e3f2fd"}}>
            <div class="container-fluid">
                <a class="navbar-brand" href="#">Home</a>
                <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div class="navbar-nav">
                            <ul class="navbar-nav">
                                    <li class="nav-item">
                                        <a class="nav-link" aria-current="page" href="#">About</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#">Fasilitas wisata</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="/paket">Paket wisata</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link " href="#" >Buku tamu</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link " href="#" >Galeri</a>
                                    </li>
                            </ul>
                    </div>
                </div>
            </div>
        </nav>

        <div className="content-home">
        <div class="row">
            <div class="col paket1">
                <div className="card" style={{ width: "18rem" }}>
                    <div className="img-card"/>
                    <div class="card-body">
                        <p class="card-text">Wonderfull Malino</p>
                    </div>
                <a href="/form-pesanan" class="btn btn-primary">Pesan</a>

                </div>
            </div>
            <div class="col paket2">
                <div className="card" style={{ width: "18rem" }}>
                    <div className="img-card"/>
                    <div class="card-body">
                        <p class="card-text">Wonderfull Toraja</p>
                    </div>
                <a href="/form-pesanan" class="btn btn-primary">Pesan</a>

                </div>
            </div>
            
            <div class="col video-yt">
            <div class="video-container">
            <iframe width="400" height="315" src="https://www.youtube.com/embed/SamXDRcxPjk?si=2sA7S5OkwS1qHPIB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            </div>

        </div>
        </div>

        <Footer />

        
        </React.Fragment>
    )
}

export default Home;
