import React,{useState,useEffect} from "react";
import './FormPesanan.css'
const FormPesanan = () => {


    return(
        <React.Fragment>
            <div className="container-form">
                <h1>Form Pesanan</h1>
                <form>
                <div className="mb-3">
                    <label className="form-label">Nama Pemesan</label>
                    <input type="text" className="form-control" />
                </div>
                <div className="mb-3">
                    <label className="form-label">No Telp</label>
                    <input type="text" className="form-control" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Waktu Pelaksanaan Perjalanan</label>
                    <input type="number" className="form-control" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Jumlah Peserta</label>
                    <input type="number" className="form-control" />
                </div>

                <div className="mb-3">
                    <label className="form-label">Pelayanan Paket Perjalanan</label>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="vehicle1" name="vehicle1" value="Bike" />
                        <label className="form-check-label" htmlFor="vehicle1">Penginapan</label>
                    </div>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="vehicle2" name="vehicle2" value="Car" />
                        <label className="form-check-label" htmlFor="vehicle2">Transportasi</label>
                    </div>
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="vehicle3" name="vehicle3" value="Boat" />
                        <label className="form-check-label" htmlFor="vehicle3">Makan</label>
                    </div>
                </div>

                <div className="mb-3">
                    <label className="form-label">Harga Paket Perjalanan</label>
                    <input type="number" className="form-control" />
                </div>

                <div className="mb-3">
                    <label className="form-label">Total Tagihan</label>
                    <input type="number" className="form-control" />
                </div>

                <button type="submit" className="btn btn-light">Submit</button>
                </form>
            </div>
        </React.Fragment>
    )
}

export default FormPesanan