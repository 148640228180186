import React, {Component,Fragment}from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,

  } from 'react-router-dom';

import Home from "../pages/Home/Home";
import DaftarPaket from "../pages/DaftarPaket/DaftarPaket";
import FormPesanan from "../pages/FormPesanan/FormPesanan";
class RoutesPages extends Component {
    render() {
        return(
            <Router>
                <Fragment>    
                    <Routes>
                    <Route 
                        path="/" 
                        element={ 
                            <Fragment>
                                <div className="container-fluid">
                                <Home />
                                </div>
                            </Fragment>                       
                        } 
                    /> 
                    <Route 
                        path="/paket" 
                        element={ 
                            <Fragment>
                                <div className="container">
                                <DaftarPaket />
                                </div>
                            </Fragment>                       
                        } 
                    /> 
                    <Route 
                        path="/form-pesanan" 
                        element={ 
                            <Fragment>
                                <div className="container">
                                <FormPesanan />
                                </div>
                            </Fragment>                       
                        } 
                    /> 
                    <Route path="*" element={<Home />} />
                    </Routes>
                </Fragment>
            </Router>
        )
    }
}
export default RoutesPages;