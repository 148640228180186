import React ,{useEffect,useState} from "react";
import axios from "axios";
import CardPaket from "../../component/CardPaket";
import './DaftarPaket.css'


const DaftarPaket = () => {
    const [pakets,setPakets] = useState([])

    const getApi = () => {
        axios.get(`${process.env.REACT_APP_API}/api/api_paket.php`)
        .then((res) => {
            console.log(res.data)
            setPakets(res.data)
        })
    }
    useEffect(()=> {
        getApi()
    },[])

    return(
        <React.Fragment> 
            <div className="header-daftar-paket">
                <h1>Daftar Paket Wisata</h1>
            </div>
            <div className="list-paket">
                {
                    pakets.map((paket,i) => {
                        return <CardPaket key={i} data={paket} />
                    })
                }
            </div>


        </React.Fragment>
    )
}

export default DaftarPaket