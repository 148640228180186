import React from "react";
import './styleComp.css'

function CardPaket(props) {

    return(
        <React.Fragment>
            <div className="card card-paket" style={{ width: "18rem" }}>
                <div className="img-card-paket" />
                <div class="card-body">
                    <p class="card-text">{props.data.nama}</p>
                </div>
                <a href="/form-pesanan" class="btn btn-primary">Pesan</a>
            </div>
        </React.Fragment>
    )
}

export default CardPaket